@media (min-width: 992px) {
  .scroll-down #header,
  .scroll-up #header {
    transform: none;
    background-color: #ffffff !important;
  }
  .scroll-up #sticky,
  .scroll-down #sticky {
    position: sticky;
    top: 0;
    z-index: 1070;
    background-color: #000000 !important;
  }
}
@media (max-width: 992px) {
  .scroll-down #header {
    transform: translate3d(0, -100%, 0);
  }
  .scroll-up #header {
    transform: none;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1070;
    background-color: #ffffff !important;
  }
  .scroll-up #header #sticky {
    background-color: #000000 !important;
  }
}
@media (max-width: 767px) {
  .scroll-up #header .usps__giftsdirect {
    display: none;
  }
}
header {
  transition: 0.2s;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .topleftpanel .logo {
  display: inline-block;
  transition: 0.2s;
  padding: 12px 0;
}
header .topmidpanel .review_top {
  line-height: 14px;
}
header .topmidpanel .review_top a {
  color: #000000 !important;
}
header .topmidpanel .review_top a .reviewaggregate strong {
  font-size: 21px;
}
header .toprightpanel i {
  color: #ffffff !important;
  font-size: 17px;
  position: relative;
  top: 4px;
}
header .toprightpanel a {
  color: #000;
}
header .toprightpanel #wishlist-item .badge {
  top: -6px;
  z-index: 1;
}
header .toprightpanel .list-inline-item {
  position: relative;
}
@media (min-width: 767px) {
  header .toprightpanel .list-inline-item.d-md-block {
    display: inline-block !important;
  }
}
header .toprightpanel .list-inline-item .fa-stack {
  color: #00aeef !important;
  top: -1px;
  right: 5px;
}
header .toprightpanel .list-inline-item .fa-stack .fa-stack-2x {
  font-size: 32px;
}
header .toprightpanel .list-inline-item .fr-phone {
  font-size: 18px;
  font-weight: 600;
}
header .toprightpanel .list-inline-item #dropdownServiceMenu {
  line-height: 11px;
  display: block;
}
header .toprightpanel .list-inline-item .dropdown-menu.show {
  min-width: 100px;
}
header .toprightpanel .list-inline-item .basket {
  text-align: center;
}
header .toprightpanel .list-inline-item .basket .badge {
  background: #ee742a !important;
  position: absolute;
  margin-left: -10px;
  top: -8px;
  right: 18px;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
  min-width: 20px;
  border-radius: 15px;
  font-family: arial, helvetica, sans-serif;
}
header .toprightpanel .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 0 0 0 10px;
}
header .toprightpanel .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
@media (max-width: 992px) {
  header {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  header.bg-light {
    background-color: #ffffff !important;
  }
}
@media (max-width: 767px) {
  header .navbar {
    padding: 0;
  }
  header .myaccount {
    display: none;
  }
}
@media (max-width: 565px) {
  header .container {
    max-width: 100%;
  }
  header .list-inline-item:first-child {
    margin-right: 0;
  }
  header .topleftpanel {
    text-align: center;
  }
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 1200px) {
  .navbar #mainnav .toplevelmenu__container li .nav-link {
    padding: 0.5rem;
  }
}

.navbar-brand {
  font-size: 14px;
  margin-right: 5px;
  padding: 0;
}

.navbar-toggler {
  border: none;
}

@media (max-width: 575px) {
  #navbar-wrapper {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  #navbar-wrapper .container {
    padding: 0 5px;
  }
  #navbar-wrapper .container .navbar-toggler {
    padding: 0.4rem 0.5rem 0.4rem 0;
  }
}

#topsearchform {
  position: absolute;
  right: 5px;
  top: 20px;
  -webkit-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  max-width: 250px;
  overflow: hidden;
}
#topsearchform * {
  outline: none;
  box-sizing: border-box;
}
#topsearchform .search__wrapper {
  position: relative;
  top: -1px;
}
#topsearchform .search__wrapper .search__field {
  width: 32px;
  height: 36px;
  color: transparent;
  padding: 0.35em 33px 0.35em 0;
  border: 0px solid transparent;
  border-radius: 0;
  cursor: pointer;
  background-color: #000000 !important;
  transition: all 0.3s ease-in-out;
}
#topsearchform .search__wrapper .search__field:focus {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  background-color: #ffffff !important;
  width: 30vw;
  padding-left: 5px;
  color: #00aeef !important;
  cursor: default;
}
#topsearchform .search__wrapper .search__field:focus ~ .search__icon {
  color: #000000 !important;
  cursor: pointer;
  pointer-events: auto;
}
#topsearchform .search__wrapper .search__field::-webkit-input-placeholder, #topsearchform .search__wrapper .search__field:-moz-placeholder, #topsearchform .search__wrapper .search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  opacity: 1;
}
#topsearchform .search__wrapper .search__field::-webkit-input-placeholder[style*=hidden], #topsearchform .search__wrapper .search__field:-moz-placeholder[style*=hidden], #topsearchform .search__wrapper .search__field:-ms-input-placeholder[style*=hidden] {
  color: #000;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
#topsearchform .search__wrapper .search__icon {
  position: absolute;
  background-color: transparent;
  top: 0;
  color: #ffffff !important;
  right: 0;
  width: 36px;
  height: 36px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  display: inline-block;
}
#topsearchform .search__wrapper .search__icon i {
  font-size: 22px;
}

#mainnav {
  width: 100%;
  max-height: 42px;
  /* Dim the screen on hover of menu item and focus on dropdown  */
}
#mainnav .toplevelmenu__container {
  width: 100%;
}
#mainnav .toplevelmenu__container .nav-item.dropdown .dropdown-menu {
  border-radius: 0;
  top: 93%;
  z-index: 1080;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
#mainnav .toplevelmenu__container .nav-item.dropdown .dropdown-menu li:hover > a, #mainnav .toplevelmenu__container .nav-item.dropdown .dropdown-menu li:focus > a {
  background-color: #f9f9f9 !important;
  color: #000000 !important;
}
#mainnav .toplevelmenu__container .nav-item.dropdown .dropdown-menu li a {
  font-size: 14px;
  font-weight: 600;
}
#mainnav .toplevelmenu__container .nav-item.dropdown .dropdown-menu li a.active {
  background-color: #ee742a !important;
  color: #fff;
}
@media (min-width: 1200px) {
  #mainnav .toplevelmenu__container .nav-item.dropdown:hover > .dropdown-menu, #mainnav .toplevelmenu__container .nav-item.dropdown:focus > .dropdown-menu {
    display: block;
  }
}
#mainnav .toplevelmenu__container li ul li ul.dropdown-menu {
  left: 100%;
  top: -3px !important;
}
#mainnav .toplevelmenu__container .nav-link {
  color: #fff;
}