webshop-product.webshop--isoutofstock .product__alwaysbeordered {
  display: none;
}

.lightbox-carousel .carousel-control-next-icon,
.lightbox-carousel .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}

html.webshop--isproductpage body {
  background-color: #ffffff !important;
}
html.webshop--isproductpage #product__images__preview__container .glide__slide img {
  max-height: 600px;
  width: auto;
  margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 767px) {
  html.webshop--isproductpage #product__images__preview__container .glide__slide img {
    max-height: 300px;
  }
}
html.webshop--isproductpage #product__images__preview__container .glide__arrows .glide__arrow {
  border: none;
  box-shadow: none;
}
html.webshop--isproductpage #product__images__preview__container .glide__arrows .glide__arrow--left {
  filter: invert(1) grayscale(100);
  left: 0;
}
html.webshop--isproductpage #product__images__preview__container .glide__arrows .glide__arrow--right {
  filter: invert(1) grayscale(100);
  right: 0;
}
html.webshop--isproductpage #product__images__grid__container {
  position: relative;
}
html.webshop--isproductpage #product__images__grid__container .box_fade {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
}
html.webshop--isproductpage #product__images__grid__container .box_fade_left {
  background-image: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0) 10%);
}
html.webshop--isproductpage #product__images__grid__container .box_fade_right {
  background-image: linear-gradient(270deg, #fff, rgba(255, 255, 255, 0) 10%);
}
html.webshop--isproductpage #product__images__grid__container .glide__slide {
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
html.webshop--isproductpage #product__images__grid__container .glide__slide img {
  opacity: 0.5;
  cursor: grab;
  max-height: 100px;
  width: auto;
  margin: 0 auto;
}
html.webshop--isproductpage #product__images__grid__container .glide__slide--active {
  border-color: #000;
}
html.webshop--isproductpage #product__images__grid__container .glide__slide--active img {
  opacity: 1;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow {
  border-radius: 50rem;
  background-color: #00aeef !important;
  border: none;
  padding: 12px;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow--left {
  left: 1em;
}
html.webshop--isproductpage #product__images__grid__container .glide__arrows .glide__arrow--right {
  right: 1em;
}
html.webshop--isproductpage .lightbox .btn-close svg {
  fill: #000;
}
html.webshop--isproductpage .lightbox .ratio-16x9 {
  --bs-aspect-ratio: 100%!important;
  background-color: #fff !important;
}
html.webshop--isproductpage .product__info .usps__giftsdirect {
  padding: 0;
}
html.webshop--isproductpage .product__info .usps__giftsdirect li {
  border: 1px solid #ccc;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  padding: 10px 20px;
}

@media (min-width: 320px) and (max-width: 576px) {
  #productpage_container {
    margin-top: 20px;
  }
}
#productpage_container .badge-danger {
  background-color: #d35f5f;
  border-radius: 0;
  border-bottom-right-radius: 0.25rem;
  padding: 6px 19px 4px 19px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
#productpage_container .product__discountslogan {
  color: #8fc13e;
  font-weight: 500;
  display: block;
  border: 1px solid #8fc13e;
  margin-bottom: 10px;
  padding: 10px;
  float: right;
  width: fit-content;
  clear: both;
  margin-top: 10px;
}
#productpage_container .product__discountslogan:empty {
  display: none;
}

.product__options {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: relative;
}
.product__options label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.product__options #promidata-price-info #priceinfo-noprint tbody tr:not(:first-child) {
  display: none;
}
.product__options .product__quantitydiscounts .quantitydiscounts_percentage {
  color: #00aeef !important;
  font-weight: 500;
  font-size: 14px;
}
.product__options input#amount {
  max-width: 120px;
}
.product__options .product__instocktext {
  font-size: 16px;
  font-weight: 500;
}
.product__options .product__instocktext i {
  color: #8fc13e;
}
.product__options .text-danger a {
  color: #dc3545;
  text-decoration: underline;
}
.product__options .readmore__prod {
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
  margin-top: 10px;
}
.product__options .webshop-product__priceblock {
  margin: 10px 0;
}
.product__options .webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  color: #d35f5f;
  position: relative;
}
.product__options .webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #d35f5f;
  border-radius: 0;
  transform: rotate(-11deg);
}
.product__options .webshop-product__price {
  font-size: 28px;
  font-weight: 400;
  color: #333;
}
.product__options .discountslogan {
  color: #8fc13e;
  font-weight: 500;
}
.product__options .product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__info {
  margin-bottom: 40px;
}
.product__info h2 {
  font-size: 32px;
  font-weight: 400;
}
.product__info .nav-pills {
  background-color: #000000 !important;
}
.product__info .nav-pills .nav-item {
  background-color: #000000 !important;
  color: #ffffff !important;
  margin-left: 15px;
  margin-top: 10px;
  border: 0;
}
.product__info .nav-pills .nav-item .nav-link {
  color: #ffffff !important;
  font-weight: 400;
  border-radius: 0;
}
.product__info .nav-pills .nav-item .nav-link.active {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 0;
}

@media (max-width: 577px) {
  .product__images {
    margin: 20px 0;
  }
}
.product__image img {
  max-height: 100px;
  width: auto;
}

.product_social_sharing {
  position: absolute;
  font-size: 24px;
  color: #00aeef;
  z-index: 1;
  right: 10px;
}
.product_social_sharing label {
  cursor: pointer;
}
.product_social_sharing i {
  margin-right: 20px;
  margin-bottom: 10px;
  color: #00aeef;
}

.tab-container {
  overflow: hidden;
}
.tab-container .tab-pane {
  margin-bottom: 50px;
}
.tab-container .tab-pane .table-sm td,
.tab-container .tab-pane .table-sm th {
  padding: 0.4rem;
}
.tab-container .tab-pane .table td,
.tab-container .tab-pane .table th {
  border-top: 0;
}
.tab-container .tab-pane .table-striped tbody tr:nth-of-type(odd) {
  background-color: #eeeeee !important;
}

.product__tags {
  margin-top: 10px;
  margin-bottom: 0;
  overflow: hidden;
}

.product__tag {
  background: transparent;
  padding: 8px 12px;
  display: block;
  float: left;
  margin: 0 10px 10px 0;
  color: #bbb;
  border-radius: 3px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 300;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  text-decoration: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: inherit;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
}

.product__related {
  margin: 15px 0;
  padding: 50px 0;
}
@media (min-width: 992px) {
  .product__related .col-lg-4 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 480px) and (max-width: 979px) {
  #productpage_container {
    padding-bottom: 40px;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .nav-link {
    display: block;
    padding: 0.5rem;
  }
  .webshop-product__optiontitle {
    width: 100%;
    margin-bottom: 10px;
  }
  .product__options {
    margin-bottom: 20px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1199px) {
  .product__options .card .in-decrease_amount {
    margin-right: 0;
  }
  .webshop-product__add {
    font-size: 15px;
    padding: 10px 12px;
    margin-left: 10px;
  }
}
html.webshop--isproductpage #ondermenu {
  margin-top: 0;
}

html.webshop--product__nonorderable .webshop-product__add {
  display: none;
}

html.product--priceonrequest .webshop-product__priceblock__column {
  display: none;
}
html.product--priceonrequest .minprice--quoterequest {
  display: block !important;
}
html.product--priceonrequest .giftsdirect-gotoquote {
  pointer-events: none;
  opacity: 0.5;
}
html.product--priceonrequest .giftsdirect-gotoquote.priceonrequest {
  pointer-events: all;
  opacity: 1;
}