@font-face {
    font-family: 'FontAwesome';
    src: url('fontawesome-webfont.woff2?v=4.7.0') format('woff2'), 
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.fa-angle-right:before {
    content: "\f105"
}
.fa-angle-up:before {
    content: "\f106"
}
.fa-angle-down:before {
    content: "\f107"
}
.fa-arrow-right:before {
    content: "\f061"
}

.fa-arrow-down:before {
    content: "\f063"
}
.fa-circle:before {
    content: "\f111"
}
.fa-check:before {
    content: "\f00c"
}
.fa-comments:before {
    content: "\f086"
}
.fa-comments-o:before {
    content: "\f0e6"
}
.fa-envelope-o:before {
    content: "\f003"
}
.fa-file-pdf-o:before {
    content: "\f1c1"
}
.fa-file-text:before {
    content: "\f15c"
}
.fa-hand-o-right:before {
    content: "\f0a4"
}
.fa-heart:before {
    content: "\f004"
}
.fa-heart-o:before {
    content: "\f08a"
}
.fa-info-circle:before {
    content: "\f05a"
}
.fa-pencil:before {
    content: "\f040"
}
.fa-phone:before {
    content: "\f095"
}
.fa-search:before {
    content: "\f002"
}
.fa-shopping-cart:before {
    content: "\f07a"
}
.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}
.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}
.fa-stack-1x,
.fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-stack-1x {
    line-height: inherit
}

.fa-stack-2x {
    font-size: 2em
}
.fa-tag:before {
    content: "\f02b"
}
.fa-trash-o:before {
    content: "\f014"
}
.fa-fw {
    width: 1.28571429em;
    text-align: center
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}


@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}