html.webshop--iscategorypage #sidebar,
html.webshop--istagpage #sidebar {
  margin-top: 0;
}
html.webshop--iscategorypage .categoryintro .usps__giftsdirect,
html.webshop--istagpage .categoryintro .usps__giftsdirect {
  padding: 10px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #00aeef !important;
  margin: 0 0 15px 0 !important;
}
html.webshop--iscategorypage .categoryintro .usps__giftsdirect li,
html.webshop--istagpage .categoryintro .usps__giftsdirect li {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #eee;
}
html.webshop--iscategorypage .categoryintro .usps__giftsdirect li a,
html.webshop--istagpage .categoryintro .usps__giftsdirect li a {
  margin-bottom: 0;
}
html.webshop--iscategorypage .categoryintro #threesteps,
html.webshop--istagpage .categoryintro #threesteps {
  border-bottom: 1px solid #00aeef !important;
  overflow: hidden;
  padding: 0 10px 15px;
  box-shadow: 0 13px 18px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 13px 18px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 13px 18px rgba(0, 0, 0, 0.1);
}
html.webshop--iscategorypage .categoryintro #threesteps .fa-stack,
html.webshop--istagpage .categoryintro #threesteps .fa-stack {
  width: 3em;
  height: 3em;
  float: left;
  top: 2px;
  margin-right: 6px;
}
html.webshop--iscategorypage .categoryintro #threesteps .fa-stack-1x,
html.webshop--istagpage .categoryintro #threesteps .fa-stack-1x {
  color: #fff;
  font-size: 22px;
  left: 0;
  top: 5px;
}
html.webshop--iscategorypage .categoryintro #threesteps .fa-circle,
html.webshop--istagpage .categoryintro #threesteps .fa-circle {
  color: #00aeef;
  font-size: 3em;
}
html.webshop--iscategorypage .categoryintro #categoryintro_collapse.collapse:not(.show),
html.webshop--istagpage .categoryintro #categoryintro_collapse.collapse:not(.show) {
  display: block;
}
html.webshop--iscategorypage .categoryintro .btn-link.collapsed:after,
html.webshop--istagpage .categoryintro .btn-link.collapsed:after {
  content: "+ Lees meer";
}
html.webshop--iscategorypage .categoryintro .btn-link:not(.collapsed):after,
html.webshop--istagpage .categoryintro .btn-link:not(.collapsed):after {
  content: "- Lees minder";
}
@media (max-width: 767px) {
  html.webshop--iscategorypage .categoryintro #categoryintro_collapse.collapse:not(.show),
  html.webshop--istagpage .categoryintro #categoryintro_collapse.collapse:not(.show) {
    display: block;
    height: 140px;
    overflow: hidden;
  }
  html.webshop--iscategorypage .categoryintro #categoryintro_collapse.collapsing,
  html.webshop--istagpage .categoryintro #categoryintro_collapse.collapsing {
    height: 3rem;
    min-height: 140px;
  }
}

.subcategory__items .col-sm-4:nth-child(3n-1) {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.subcategory__items .col-sm-4 .categoryitem {
  color: #000000;
  overflow: hidden;
  padding: 10px;
  background: url(../../web/img/prodsm-bg-bottom.gif) repeat-x bottom left #fff;
  transition: all 0.2s ease-in-out 0s;
  position: relative;
  margin: 0 0 20px 0;
}
.subcategory__items .col-sm-4 .categoryitem__title {
  font-size: 19px;
}
.subcategory__items .col-sm-4 .categoryitem .btn {
  position: relative;
  bottom: -20px;
}

html.sidebarjs--open {
  overflow-y: hidden;
}

#productsgrid_anchor {
  position: absolute;
  top: -150px;
  left: 0;
}

.product__related .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

.categorygrid:empty {
  display: none;
}
@media (max-width: 565px) {
  .categorygrid {
    margin-bottom: 50px;
    padding: 15px 0;
    margin-right: -15px;
  }
}

.webshop-products__item[data-webshop-in-stock="0"] .productitem .product__stockinfo .in_stock {
  display: none;
}
.webshop-products__item[data-webshop-in-stock="0"] .productitem .product__stockinfo .out_of_stock {
  display: block;
  color: #d35f5f;
}

.productsgrid .product-overview .col-sm-4:nth-child(3n-1) {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}
.productsgrid__product--hidden {
  display: none;
}

.productitem {
  border-radius: 0;
  min-height: 385px;
}
.productitem .product__stockinfo {
  padding: 9px 1rem 0px 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.productitem .product__stockinfo .in_stock {
  color: #00aeef !important;
}
.productitem .product__stockinfo .out_of_stock {
  display: none;
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: 0 !important;
}
.productitem.ribbon:before {
  width: 85px;
  height: 65px;
  background-size: 85px;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
}
.productitem.ribbon-laagste-prijsgarantie:before {
  background-image: url("../../web/img/ribbons/ribbon-laagste-prijsgarantie.png");
}
.productitem.ribbon-eco:before {
  background-image: url("../../web/img/ribbons/ribbon-eco.png");
}
.productitem.ribbon-nieuw-png:before {
  background-image: url("../../web/img/ribbons/ribbon-nieuw.png");
}
.productitem.ribbon-tip:before, .productitem.ribbon-a118:before {
  background-image: url("../../web/img/ribbons/ribbon-tip.png");
}
.productitem .btn {
  visibility: hidden;
}
.productitem:hover, .productitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2) !important;
  z-index: 10;
  text-decoration: none;
  color: inherit;
  z-index: 1;
}
.productitem:hover .productitem__price, .productitem:focus .productitem__price {
  display: none;
}
.productitem:hover .btn, .productitem:focus .btn {
  visibility: visible;
  background-color: #ff873e !important;
  border-color: #ff873e !important;
  color: #ffffff !important;
}
.productitem.filtervalue-ribbon:before {
  width: 124px;
  height: 124px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
}
.productitem .productitem__image img {
  max-height: 200px;
  width: auto;
}
.productitem .card-body {
  padding: 1rem;
}
.productitem .card-body .productitem__rating {
  display: flex;
}
.productitem .card-body .productitem__rating .rating-stars {
  background-image: url(../../web/img/stars-review-grey-sm.svg);
  height: 17px;
  width: 90px;
}
.productitem .card-body .productitem__rating .rating-stars > .inner {
  background-image: url(../../web/img/stars-review-gold-sm.svg);
  background-size: 90px;
}
.productitem .card-body .productitem__description {
  font-size: 15px;
  line-height: 21px;
}
.productitem .card-body .productitem__description p {
  margin-bottom: 0;
}
.productitem .card-footer {
  position: relative;
  padding: 1rem;
  border-top: 0;
}
.productitem__title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
  line-height: initial;
}
.productitem__discountslogan {
  color: #00aeef !important;
  font-size: 12px;
  background-color: #ffffff !important;
  border: 1px solid #00aeef !important;
  padding: 1px 9px;
  margin-bottom: 5px;
  font-weight: 600;
  text-align: center;
}
.productitem .productitem__price {
  position: absolute;
  bottom: 30px;
  right: 10px;
  background: #00aeef !important;
  color: #fff;
  padding: 2px 13px;
  text-align: center;
  line-height: 20px;
}
.productitem .productitem__price .talk-bubble {
  position: absolute;
  display: block;
  width: 35px;
  height: 20px;
  background: url(../../web/img/ballon-left-blauw.png) no-repeat left center;
  margin-top: -6px;
  left: -35px;
  top: 30%;
}
.productitem__baseprice {
  line-height: 14px;
}
.productitem__baseprice > .price {
  display: inline-block;
  font-size: 17px;
  font-weight: 400;
  position: relative;
  color: #ee742a !important;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid #ee742a !important;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
}
.productitem__nowprice > span {
  display: block;
  font-size: 18px;
  font-weight: 600;
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem .btn {
  position: absolute;
  bottom: 30px;
  right: 10px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

html:not(.webshop--ishomepage) .category__blocks {
  padding: 30px 0;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  position: relative;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem:hover, html:not(.webshop--ishomepage) .category__blocks .categoryitem:focus {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem .categoryitem__image {
  text-align: center;
  margin-bottom: 10px;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem .categoryitem__image img {
  border-radius: 6px;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem:not(.no-gradient) .categoryitem__title {
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  color: #fff;
  padding: 20px 10px;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  font-weight: 700;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 13px;
  text-shadow: 0 2px 4px #000;
}
html:not(.webshop--ishomepage) .category__blocks .categoryitem.no-gradient .categoryitem__title {
  overflow: hidden;
  white-space: inherit;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 0;
  color: #000000;
  padding: 20px 10px;
  left: 0;
  width: 100%;
  font-size: 16px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));
  text-shadow: 0 2px 4px #fff;
}
@media (min-width: 320px) and (max-width: 767px) {
  html:not(.webshop--ishomepage) .category__blocks .categoryitem.no-gradient .categoryitem__title {
    background: rgba(255, 255, 255, 0.7);
  }
}
@media (min-width: 1400px) {
  html:not(.webshop--ishomepage) .category__blocks .container {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  html:not(.webshop--ishomepage) .category__blocks .col-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.productfilter-kleur .productfilter--optionlist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.productfilter-kleur .productfilter--optionlist li {
  display: inline-block;
  margin: 0 7px 7px 0;
  width: 30px;
  height: 30px;
  border-radius: 0.25rem;
  position: relative;
  border-top: 0;
}
.productfilter-kleur .productfilter--optionlist li input {
  visibility: hidden;
}
.productfilter-kleur .productfilter--optionlist li input:checked + label {
  border: 2px solid #000;
}
.productfilter-kleur .productfilter--optionlist li label {
  color: transparent !important;
  display: block;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  text-indent: -999px;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-geel {
  background: yellow;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-zwart {
  background: black;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-wit {
  background: white;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-rood {
  background: red;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-oranje {
  background: orange;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-groen {
  background: green;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-blauw {
  background: blue;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-paars {
  background: purple;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-roze {
  background: pink;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-bruin {
  background: #964B00;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-transparant {
  background: linear-gradient(124deg, transparent, rgba(255, 255, 255, 0.3));
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-grijs {
  background: grey;
}
.productfilter-kleur .productfilter--optionlist li .filtervalue-multicolor {
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}

.product_color span {
  display: inline-block;
  margin: 0 5px 5px 0;
  height: 12px;
  width: 12px;
}
.product_color__geel, .product_color__yellow {
  background: yellow;
}
.product_color__zwart, .product_color__black {
  background: black;
}
.product_color__wit, .product_color__white {
  background: #f9f9f9;
  box-shadow: 0px 0px 1px inset #000;
}
.product_color__rood, .product_color__red {
  background: red;
}
.product_color__oranje, .product_color__orange {
  background: orange;
}
.product_color__groen, .product_color__green {
  background: green;
}
.product_color__blauw, .product_color__blue {
  background: blue;
}
.product_color__paars, .product_color__purple {
  background: purple;
}
.product_color__roze, .product_color__pink {
  background: pink;
}
.product_color__bruin, .product_color__brown {
  background: #964B00;
}
.product_color__transparant {
  background: linear-gradient(124deg, transparent, rgba(255, 255, 255, 0.3));
}
.product_color__grijs, .product_color__grey {
  background: grey;
}
.product_color__multicolor {
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
}