@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url(../web/fonts/open-sans.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url(../web/fonts/open-sans.woff2) format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url(../web/fonts/open-sans.woff2) format('woff2');
}

/* light, normal, semi-bold, bold */
html
{
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-display: swap;
}

body
{
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 23px;
  font-weight:400;
  color:#000;
  font-display: swap;
}

h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a
{
    color: #000000;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-display: swap;
    margin-bottom: 10px;
    line-height: 1.3;
}
h1
{
    font-size: 32px;
    font-weight: 600;
}
h2
{
    font-size: 24px;
}
h3
{
    font-size: 22px;
}
h4
{
    font-size: 20px;
}
h1, h2, h3, h4, h5, h6
{
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1.1;
}
p
{
    margin-top: 0;
    margin-bottom: 1rem;
}
a,
.btn-link
{
    color: #00aeef;
    text-decoration: none;
}
.button a
{
    display: inline-block;
    width: auto;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #ee742a;
    border-color: #ee742a;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.button a:hover,
.button a:focus,
.button a:active,
.button a.active,
.button a.active:hover
{
  background-color: #ff873e;
  border-color: #ff873e;
  text-decoration: none;
}
ul.checkmarks,
ul.angles,
ul.remove
{
    margin:0 0 10px 0;
    padding:0
}
ul.checkmarks li,
ul.angles li,
ul.remove li
{
    position: relative;
    list-style-type: none;
    padding: 0 0 0 45px;
}
ul.checkmarks li:before
{
    content: '\f00c';
    position: absolute;
    left: 18px;
    font-family: fontAwesome;
    font-size: 19px;
    top: 0;
    color: #59b136;
}

ul.angles li:before
{
    content: '\f105';
    position: absolute;
    left: 18px;
    font-family: fontAwesome;
    font-size: 19px;
    top: 0;
    color: #00aeef;
}

ul.remove li:before
{
    content: '\f00d';
    position: absolute;
    left: 18px;
    font-family: fontAwesome;
    font-size: 19px;
    top: 0;
    color: #00aeef;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}
.intro
{
  font-size: 19px;
  line-height: 28px;
}
.top5
{
    text-align: center;
}
.top5 h1 b,
.top5 h2 b,
.top5 h3 b
{
    display: inline-block;
    background-color: #fcc613;
    padding: 10px 10px;
    font-weight: 700;
}
.top5 h1,
.top5 h2,
.top5 h3
{
  margin-top: 25px;
  margin-bottom: 0;  
}

.wh-rtd__img,
.img-fluid
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__table .wh-rtd__img
{
  max-width: calc(100vw - 40px);
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  border-top: 0;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #584a45;
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}

.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
/*
.wh-rtd__table tr + tr > td
{
  border-top: 1px solid #dadada;
}
*/
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}


/**/
.widget-categoriebanners img
{
  width:100%;
}

.twocol__banner
{
   background-color:#5e5045;
}
.twocol__banner h2,
.twocol__banner p
{
  color:#fff;
}

.twocol__banner .col-inner-left
{
  max-width: 640px;
  float:right;
  padding-right: 50px;
  padding-left:0;
}

@media (min-width: 320px) and (max-width: 1024px)
{
  .twocol__banner .col-inner-left
  {
    padding:30px;
  }
}
@media (min-width: 767px) and (max-width: 991px)
{
  .twocol__banner .col-inner-left
  {
    padding: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px)
{
  .twocol__banner .col-inner-left
  {
    max-width: 450px;
    padding-right: 0;
  }
}
@media (min-width: 1200px) and (max-width: 1400px)
{
  .twocol__banner .col-inner-left
  {
    max-width: 540px;
  }
}
